var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { cols: "12" } }, [
        _c("p", { staticClass: "primary fs-24 fw-bold py-2" }, [
          _vm._v("Tarjeta de Recaudo"),
        ]),
        _c("p", { staticClass: "fs-14 text-justify" }, [
          _vm._v(
            " Genera tu tarjeta de recaudo para realizar tus pagos en cualquiera de nuestros convenios a nivel nacional. "
          ),
          _c(
            "span",
            {
              staticClass: "primary cursor-pointer",
              on: {
                click: function ($event) {
                  return _vm.useModal("modalC")
                },
              },
            },
            [_vm._v("Ver convenios")]
          ),
        ]),
      ]),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Número de obligación" } },
            [
              _c(
                "b-form-select",
                {
                  model: {
                    value: _vm.creditoSelected,
                    callback: function ($$v) {
                      _vm.creditoSelected = $$v
                    },
                    expression: "creditoSelected",
                  },
                },
                [
                  _c("b-form-select-option", { attrs: { value: null } }, [
                    _vm._v("Seleccione número de obligación"),
                  ]),
                  _vm._l(_vm.creditosActivos, function (credito) {
                    return _c(
                      "b-form-select-option",
                      {
                        key: credito.numeroObligacion,
                        attrs: { value: credito },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              "Nro de Obligación " +
                                credito.numeroObligacion +
                                " - " +
                                credito.estado.toUpperCase()
                            ) +
                            " "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-col",
        {
          staticClass: "d-flex justify-content-end pb-5",
          attrs: { cols: "12" },
        },
        [
          _c(
            "b-button",
            {
              attrs: {
                variant: "outline-danger",
                disabled: _vm.creditoSelected === null,
              },
              on: {
                click: function ($event) {
                  return _vm.getTarjetaRecaudo("M")
                },
              },
            },
            [_c("i", { staticClass: "las la-mobile" }), _vm._v(" En móvil ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "ml-3",
              attrs: {
                variant: "danger",
                disabled: _vm.creditoSelected === null,
              },
              on: {
                click: function ($event) {
                  return _vm.getTarjetaRecaudo("P")
                },
              },
            },
            [_c("i", { staticClass: "las la-print" }), _vm._v(" Imprimir ")]
          ),
        ],
        1
      ),
      _vm.archivo !== null
        ? [
            _vm.tipoArchivo === "P"
              ? _c(
                  "b-col",
                  {
                    staticClass: "content-center my-3",
                    attrs: { cols: "12", lg: "10", "offset-lg": "1" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "content-center pointer-card-info" },
                      [
                        _c("div", { staticClass: "d-none d-sm-block" }, [
                          _c("object", {
                            staticClass: "content-pdf",
                            attrs: {
                              type: "application/pdf",
                              data:
                                "data:application/pdf;base64," + _vm.archivo,
                            },
                          }),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-block d-sm-none py-4" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "danger" },
                                on: { click: _vm.descargarTarjeta },
                              },
                              [_vm._v("Descargar")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.tipoArchivo === "M"
              ? _c("ModalTarjetaRecaudo", {
                  attrs: { modal: _vm.modales.modalTR, archivo: _vm.archivo },
                  on: { useModal: _vm.useModal },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _c("ModalConvenios", {
        attrs: { modal: _vm.modales.modalC },
        on: { useModal: _vm.useModal },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }