<template>
  <b-modal
    size="xl"
    centered
    hide-footer
    hide-header
    v-model="modal.isOpen"
    @close="onCloseModal()"
    dialog-class="w-fit-content"
  >
    <b-row class="px-4">
      <b-col v-if="!isRotate" cols="12">
        <p class="primary fs-12 text-justify">
          Para garantizar una mejor visualización de la tarjeta de pago y su correcta lectura en dispositivos de escaneo
          óptico, coloca tu dispositivo en posición horizontal.
        </p>
        <div class="text-center">
          <img class="img-fluid" src="@/assets/images/anothers/rotar.gif" />
        </div>
      </b-col>
      <b-col v-else cols="12" class="content-center">
        <img class="img-fluid" :src="`data:image/png;base64,${this.archivo}`" alt="tarjeta recaudo" />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  props: {
    archivo: { type: String, default: "" },
    modal: {
      type: Object,
      default: () => {
        return { isOpen: false };
      },
    },
  },
  data() {
    return { isRotate: false };
  },
  created() {
    this.changePantalla();
    screen.orientation.addEventListener("change", () => {
      this.changePantalla();
    });
  },
  methods: {
    onCloseModal() {
      this.$emit("useModal", "modalTR");
    },
    changePantalla() {
      if (window.screen.orientation.type === "landscape-primary") this.isRotate = true;
      else this.isRotate = false;
    },
  },
};
</script>
