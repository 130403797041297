var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { size: "xl", centered: "", "hide-footer": "", scrollable: "" },
      on: {
        close: function ($event) {
          return _vm.onCloseModal()
        },
      },
      model: {
        value: _vm.modal.isOpen,
        callback: function ($$v) {
          _vm.$set(_vm.modal, "isOpen", $$v)
        },
        expression: "modal.isOpen",
      },
    },
    [
      _c(
        "b-row",
        { staticClass: "p-3" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("p", { staticClass: "primary fs-24 fw-bold pb-2" }, [
              _vm._v("Convenios"),
            ]),
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("p", { staticClass: "primary fs-18 fw-bold" }, [
                _c("i", { staticClass: "las la-star danger fs-18" }),
                _vm._v(" Pagos con Tarjeta Digital"),
              ]),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "py-3", attrs: { cols: "12", lg: "6" } },
                    [
                      _c("p", { staticClass: "primary fs-14 pl-4" }, [
                        _vm._v("Bancos y sus corresponsales"),
                      ]),
                      _c("img", {
                        staticClass: "mx-2 mx-lg-4 my-2",
                        attrs: {
                          src: require("@/assets/images/convenios/Bancolombia-ProgresSER.png"),
                          width: "105px",
                        },
                      }),
                      _c("img", {
                        staticClass: "mx-2 mx-lg-4 my-2",
                        attrs: {
                          src: require("@/assets/images/convenios/Banco-de-Bogota-ProgresSER.png"),
                          width: "105px",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "py-3", attrs: { cols: "12", lg: "6" } },
                    [
                      _c("p", { staticClass: "primary fs-14 pl-4" }, [
                        _vm._v("Puntos Baloto, Servicios de Giros y/o Remesas"),
                      ]),
                      _c("img", {
                        staticClass: "m-2",
                        attrs: {
                          src: require("@/assets/images/convenios/Baloto-ProgresSER.png"),
                          width: "105px",
                        },
                      }),
                      _c("img", {
                        staticClass: "m-2",
                        attrs: {
                          src: require("@/assets/images/convenios/Western-Union-ProgresSER.png"),
                          width: "105px",
                        },
                      }),
                      _c("img", {
                        staticClass: "m-2",
                        attrs: {
                          src: require("@/assets/images/convenios/Efecty-ProgresSER.png"),
                          width: "105px",
                        },
                      }),
                      _c("img", {
                        staticClass: "m-2",
                        attrs: {
                          src: require("@/assets/images/convenios/Dimonex-ProgresSER.png"),
                          width: "105px",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "py-3",
                      attrs: { cols: "12", lg: "6", "offset-lg": "6" },
                    },
                    [
                      _c("p", { staticClass: "primary fs-14 pl-4" }, [
                        _vm._v("Grandes Superficies / Supermercados de cadena"),
                      ]),
                      _c("img", {
                        staticClass: "m-2",
                        attrs: {
                          src: require("@/assets/images/convenios/Almacenes-Exito-ProgresSER.png"),
                          width: "105px",
                        },
                      }),
                      _c("img", {
                        staticClass: "m-2",
                        attrs: {
                          src: require("@/assets/images/convenios/Supermercados-Carulla-ProgresSER.png"),
                          width: "105px",
                        },
                      }),
                      _c("img", {
                        staticClass: "m-2",
                        attrs: {
                          src: require("@/assets/images/convenios/Supermercados-Pomona-ProgresSER.png"),
                          width: "105px",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c("p", { staticClass: "primary fs-14 py-2 text-justify" }, [
                _vm._v(
                  " Puedes pagar con tu tarjeta digital ProgreSER en las entidades y corresponsales bancarios, así como tambien en las grandes superfiecies mencionadas. "
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("p", { staticClass: "primary fs-18 fw-bold" }, [
                _c("i", { staticClass: "las la-star danger fs-18" }),
                _vm._v(" Pagos con Cédula"),
              ]),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "py-3",
                      attrs: { cols: "12", lg: "6", xl: "4" },
                    },
                    [
                      _c("p", { staticClass: "primary fs-14 pl-4" }, [
                        _vm._v("Puntos Efecty"),
                      ]),
                      _c("img", {
                        staticClass: "mx-2 mx-lg-4 my-2",
                        attrs: {
                          src: require("@/assets/images/convenios/Efecty-ProgresSER.png"),
                          width: "105px",
                        },
                      }),
                      _c("img", {
                        staticClass: "mx-2 mx-lg-4 my-2",
                        attrs: {
                          src: require("@/assets/images/convenios/Dimonex-ProgresSER.png"),
                          width: "105px",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "content-center",
                      attrs: { cols: "12", lg: "6", xl: "8" },
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "primary fs-14 py-2 text-justify" },
                        [
                          _c("li", [
                            _vm._v(
                              " Puedes pagar presentando tu cédula en las entidades y corresponsales bancarios, grandes superfiecies. "
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              "En puntos Efecty y Dominex podrás realizar pagos bajo el convenio 111219."
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "content-center", attrs: { cols: "12" } },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.onCloseModal()
                    },
                  },
                },
                [_vm._v("Cerrar")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }