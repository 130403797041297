<template>
  <b-modal size="xl" centered hide-footer scrollable v-model="modal.isOpen" @close="onCloseModal()">
    <b-row class="p-3">
      <b-col cols="12">
        <p class="primary fs-24 fw-bold pb-2">Convenios</p>
      </b-col>
      <b-col cols="12">
        <p class="primary fs-18 fw-bold"><i class="las la-star danger fs-18"></i> Pagos con Tarjeta Digital</p>
        <b-row>
          <b-col cols="12" lg="6" class="py-3">
            <p class="primary fs-14 pl-4">Bancos y sus corresponsales</p>
            <img class="mx-2 mx-lg-4 my-2" src="@/assets/images/convenios/Bancolombia-ProgresSER.png" width="105px" />
            <img
              class="mx-2 mx-lg-4 my-2"
              src="@/assets/images/convenios/Banco-de-Bogota-ProgresSER.png"
              width="105px"
            />
          </b-col>
          <b-col cols="12" lg="6" class="py-3">
            <p class="primary fs-14 pl-4">Puntos Baloto, Servicios de Giros y/o Remesas</p>
            <img class="m-2" src="@/assets/images/convenios/Baloto-ProgresSER.png" width="105px" />
            <img class="m-2" src="@/assets/images/convenios/Western-Union-ProgresSER.png" width="105px" />
            <img class="m-2" src="@/assets/images/convenios/Efecty-ProgresSER.png" width="105px" />
            <img class="m-2" src="@/assets/images/convenios/Dimonex-ProgresSER.png" width="105px" />
          </b-col>
          <b-col cols="12" lg="6" offset-lg="6" class="py-3">
            <p class="primary fs-14 pl-4">Grandes Superficies / Supermercados de cadena</p>
            <img class="m-2" src="@/assets/images/convenios/Almacenes-Exito-ProgresSER.png" width="105px" />
            <img class="m-2" src="@/assets/images/convenios/Supermercados-Carulla-ProgresSER.png" width="105px" />
            <img class="m-2" src="@/assets/images/convenios/Supermercados-Pomona-ProgresSER.png" width="105px" />
          </b-col>
        </b-row>
        <p class="primary fs-14 py-2 text-justify">
          Puedes pagar con tu tarjeta digital ProgreSER en las entidades y corresponsales bancarios, así como tambien en
          las grandes superfiecies mencionadas.
        </p>
      </b-col>
      <b-col cols="12">
        <p class="primary fs-18 fw-bold"><i class="las la-star danger fs-18"></i> Pagos con Cédula</p>
        <b-row>
          <b-col cols="12" lg="6" xl="4" class="py-3">
            <p class="primary fs-14 pl-4">Puntos Efecty</p>
            <img class="mx-2 mx-lg-4 my-2" src="@/assets/images/convenios/Efecty-ProgresSER.png" width="105px" />
            <img class="mx-2 mx-lg-4 my-2" src="@/assets/images/convenios/Dimonex-ProgresSER.png" width="105px" />
          </b-col>
          <b-col cols="12" lg="6" xl="8" class="content-center">
            <ul class="primary fs-14 py-2 text-justify">
              <li>
                Puedes pagar presentando tu cédula en las entidades y corresponsales bancarios, grandes superfiecies.
              </li>
              <li>En puntos Efecty y Dominex podrás realizar pagos bajo el convenio 111219.</li>
            </ul>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="content-center">
        <b-button variant="danger" @click="onCloseModal()">Cerrar</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  props: {
    modal: {
      type: Object,
      default: () => {
        return { isOpen: false };
      },
    },
  },
  methods: {
    onCloseModal() {
      this.$emit("useModal", "modalC");
    },
  },
};
</script>
