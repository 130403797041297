<template>
  <b-row>
    <b-col cols="12">
      <p class="primary fs-24 fw-bold py-2">Tarjeta de Recaudo</p>
      <p class="fs-14 text-justify">
        Genera tu tarjeta de recaudo para realizar tus pagos en cualquiera de nuestros convenios a nivel nacional.
        <span class="primary cursor-pointer" @click="useModal('modalC')">Ver convenios</span>
      </p>
    </b-col>
    <b-col cols="12">
      <b-form-group label="Número de obligación">
        <b-form-select v-model="creditoSelected">
          <b-form-select-option :value="null">Seleccione número de obligación</b-form-select-option>
          <b-form-select-option :value="credito" :key="credito.numeroObligacion" v-for="credito in creditosActivos">
            {{ `Nro de Obligación ${credito.numeroObligacion} - ${credito.estado.toUpperCase()}` }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-col>
    <b-col cols="12" class="d-flex justify-content-end pb-5">
      <b-button variant="outline-danger" :disabled="creditoSelected === null" @click="getTarjetaRecaudo('M')">
        <i class="las la-mobile" /> En móvil
      </b-button>
      <b-button variant="danger" class="ml-3" :disabled="creditoSelected === null" @click="getTarjetaRecaudo('P')">
        <i class="las la-print" /> Imprimir
      </b-button>
    </b-col>
    <template v-if="archivo !== null">
      <b-col v-if="tipoArchivo === 'P'" cols="12" lg="10" offset-lg="1" class="content-center my-3">
        <div class="content-center pointer-card-info">
          <div class="d-none d-sm-block">
            <object class="content-pdf" type="application/pdf" :data="`data:application/pdf;base64,${archivo}`" />
          </div>
          <div class="d-block d-sm-none py-4">
            <b-button variant="danger" @click="descargarTarjeta">Descargar</b-button>
          </div>
        </div>
      </b-col>
      <ModalTarjetaRecaudo
        v-if="tipoArchivo === 'M'"
        :modal="modales.modalTR"
        @useModal="useModal"
        :archivo="archivo"
      />
    </template>
    <ModalConvenios :modal="modales.modalC" @useModal="useModal" />
  </b-row>
</template>

<script>
import PagosService from "@/app/core/api/pagos";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import AlertsService from "@/app/shared/services/alerts.service";

// COMPONENTS
import ModalConvenios from "../components/ModalConvenios.vue";
import ModalTarjetaRecaudo from "../components/ModalTarjetaRecaudo.vue";

export default {
  components: { ModalTarjetaRecaudo, ModalConvenios },
  mixins: [mixinsEvents],
  data() {
    return {
      archivo: null,
      tipoArchivo: null,
      creditosActivos: [],
      creditoSelected: null,
      modales: { modalTR: { isOpen: false }, modalC: { isOpen: false } },
    };
  },
  created() {
    this.getCreditosActivos();
  },
  methods: {
    useModal(modalId) {
      this.modales[modalId].isOpen = !this.modales[modalId].isOpen;
    },
    getCreditosActivos() {
      this.$store.getters.creditos.map((item) => {
        if (item.edadCartera === 20 || item.diasEnMora > 0) this.creditosActivos.push({ ...item, estado: "ACTIVO" });
      });

      if (this.$store.getters.creditoSelected !== null)
        return (this.creditoSelected = this.$store.getters.creditoSelected);

      if (this.creditosActivos.length === 0) {
        this.$router.push("/home");
        AlertsService.error("Tarjeta de Recaudo", "No tienes ningun credito activo.");
      }
      if (this.creditosActivos.length === 1) this.creditoSelected = this.creditosActivos[0];
    },
    getTarjetaRecaudo(_tipo) {
      this.tipoArchivo = _tipo;

      const body = {
        TipoDispositivo: _tipo,
        ValorPago: this.creditoSelected.valorPago,
        Celular: this.$store.getters.numeroCelular,
        Cedula: this.$store.getters.identificacion,
        Nombre: this.creditoSelected.nombreCompleto,
        Credito: this.creditoSelected.numeroObligacion,
        CodigoTarjeta: this.creditoSelected.codigoBarras,
        FechaProximoPago: this.creditoSelected.fechaProximoPago,
      };

      PagosService.getTarjetaRecaudo(body).then((response) => {
        const data = response.data;
        if (data.Estado) {
          if (_tipo === "M") {
            this.archivo = response.data.ReporteBase64;
            this.useModal("modalTR");
          }
          if (_tipo === "P") {
            this.archivo = response.data.ReporteBase64;
          }
        }
      });
    },
    descargarTarjeta() {
      let element = document.createElement("a");
      element.setAttribute("href", `data:application/pdf;base64,${this.archivo}`);
      element.setAttribute("download", `Tarjeta de Recaudo`);
      document.body.appendChild(element);
      element.click();
    },
  },
};
</script>
