import { render, staticRenderFns } from "./TarjetaRecaudo.vue?vue&type=template&id=37c17cb2"
import script from "./TarjetaRecaudo.vue?vue&type=script&lang=js"
export * from "./TarjetaRecaudo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\PROYECTOS TRABAJO\\2 VUE\\sucursal-virtual-develop\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('37c17cb2')) {
      api.createRecord('37c17cb2', component.options)
    } else {
      api.reload('37c17cb2', component.options)
    }
    module.hot.accept("./TarjetaRecaudo.vue?vue&type=template&id=37c17cb2", function () {
      api.rerender('37c17cb2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/modules/pagos/views/TarjetaRecaudo.vue"
export default component.exports